import React from "react";
import { NavLink } from "react-router-dom";
import "../../styles/pageNotFound.css";

const PageNotFound = () => {
  return (
    <div className="body">
      <div className="number404">404</div>
      <div className="text404">
        <span>¡Ups!</span>
        <br />
        No encontramos lo que buscabas.
      </div>
      <div className="card-text">
        Parece que el enlace que estás intentando abrir no está disponible. No
        te preocupes, tenemos una solución alternativa para ti. Haz clic aquí
        para acceder al contenido que estabas buscando.
      </div>
      <NavLink to="/" className="mt-3 btn-return404">
        Regresar a inicio
      </NavLink>
    </div>
  );
};

export default PageNotFound;
