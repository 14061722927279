class SessionManager {
  static instance = null;

  constructor(timeout = 40 * 60 * 1000) {
    if (SessionManager.instance) {
      return SessionManager.instance; // Si ya existe una instancia, la devuelve.
    }

    this.timeout = timeout;
    this.timerId = null;
    this.checkIntervalId = null;

    // Inicializar o recuperar el tiempo de inicio de sesión
    this.initSessionTimer();

    // Iniciar el verificador del tiempo de sesión
    this.startSessionChecker();

    // Asignar la instancia única
    SessionManager.instance = this;
  }

  initSessionTimer() {
    const storedStartTime = localStorage.getItem("sessionStartTime");

    if (!storedStartTime) {
      // Primera vez que se inicia la sesión
      const startTime = Date.now();
      localStorage.setItem("sessionStartTime", startTime.toString());
    } else {
      const elapsedTime = Date.now() - parseInt(storedStartTime);
      const remainingTime = this.timeout - elapsedTime;

      if (remainingTime <= 0) {
        // La sesión ha expirado
        console.log("La sesión ha expirado durante la recarga/navegación");
        this.logout();
        return;
      }

      console.log(
        `Sesión recuperada. Tiempo restante: ${Math.round(
          remainingTime / 60000
        )} minutos`
      );
    }
  }

  startSessionChecker() {
    this.checkIntervalId = setInterval(() => {
      this.checkSessionExpiration();
    }, 10000);

    const storedStartTime = localStorage.getItem("sessionStartTime");
    if (storedStartTime) {
      const elapsedTime = Date.now() - parseInt(storedStartTime);
      const remainingTime = Math.max(0, this.timeout - elapsedTime);

      this.timerId = setTimeout(() => {
        this.logout();
      }, remainingTime);
    }
  }

  checkSessionExpiration() {
    const storedStartTime = localStorage.getItem("sessionStartTime");
    if (!storedStartTime) return;

    const elapsedTime = Date.now() - parseInt(storedStartTime);
    const remainingTime = this.timeout - elapsedTime;

    if (remainingTime <= 0) {
      console.log("¡Sesión expirada!");
      this.logout();
    }
  }

  logout() {
    this.cleanup();
    localStorage.removeItem("sessionStartTime");
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  }

  cleanup() {
    clearTimeout(this.timerId);
    clearInterval(this.checkIntervalId);
  }

  resetSession() {
    localStorage.setItem("sessionStartTime", Date.now().toString());
    this.cleanup();
    this.startSessionChecker();
    console.log("Sesión reiniciada manualmente");
  }
}

export default SessionManager;
