import React, { useEffect } from "react";
import "./styles/main.css";
import ReactGA from "react-ga";
import Main from "./layout/main";
import RouterApp from "./router/routes";
import { BrowserRouter } from "react-router-dom";
import { DataProvider } from "./context/DataContext";
import { UserMoraProvider } from "./context/userMoraContext";
import { SectionProvider } from "./context/SectionContext";
import { Toaster } from "react-hot-toast";

const TRACKING_ID = "G-BJ1KD1C6WZ";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  useEffect(() => {
    // Cleanup function cuando el componente se desmonte
    return () => {
      if (window.sessionManager) {
        window.sessionManager.cleanup();
      }
    };
  }, []);

  return (
    <BrowserRouter>
      <SectionProvider>
        <UserMoraProvider>
          <DataProvider>
            <Toaster />
            <Main>
              <RouterApp />
            </Main>
          </DataProvider>
        </UserMoraProvider>
      </SectionProvider>
    </BrowserRouter>
  );
};

export default App;
