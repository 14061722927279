import { useEffect, useState } from "react";
import { getInfo } from "../../service/getInfo";
import Load from "../../common/Load";

const InfoMembership = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getInfo();
        setUserData(response.data.data.info);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  if (!userData) {
    return <Load />;
  }

  const { afiliado1, afiliado2, contracto } = userData;
  const {
    membresia,
    contrato: contratoInfo,
    afiliados,
    beneficiarios,
    noches,
  } = contracto;

  return (
    <section className="section-account-payments">
      <article className="info-user__details">
        <h2>
          <strong>Mi cuenta</strong>
        </h2>

        <p>{afiliado1?.titular}</p>
        <p>{afiliado2?.titular}</p>
        <p>
          <span>Membresía:</span> {membresia}
        </p>
        <p>
          <span>Contrato:</span> {contratoInfo}
        </p>
        <p>
          <span>Afiliados:</span> {afiliados}
        </p>
        <p>
          <span>Beneficiarios:</span> {beneficiarios}
        </p>
        <p>
          <span>Noches por año:</span> {noches?.slice(0, 2) || 0} días por año
        </p>
      </article>
    </section>
  );
};

export default InfoMembership;
