import { toast } from "react-hot-toast";
import { getPrice } from "../service/getPrice";
import { useCustomNavigation } from "../hooks/useNavigateGAC";

export const useHandleTokenPrice = () => {
  const { handleNavigation } = useCustomNavigation(); // Usa el custom hook

  const handleTokenPrice = async (isStateMora) => {
    if (isStateMora === "1") {
      toast.custom(
        (t) => (
          <div className={`go2072408551 ${t.visible ? "show" : "hide"}`}>
            <img src="/logo.ico" alt="Icono de Mora" />
            <div className="go3958317564">Tu plan vacacional presenta mora</div>
            <button
              onClick={() => handleNavigation("placeOrder")} // Llama a la función de navegación
              className="btn-payU"
            >
              <span>
                <img
                  src="/icons/card_pay.svg"
                  alt="img mercado de pago"
                  width={38}
                  style={{ marginRight: "10px" }}
                />
                Realiza tú Pago
              </span>
            </button>
          </div>
        ),
        {
          duration: 5000,
          position: "top-center",
        }
      );
    }

    // Lógica para obtener el precio
    try {
      const storedPrice = sessionStorage.getItem("priceURL");

      if (storedPrice) {
        return window.open(storedPrice, "_blank");
      } else {
        const response_price = await getPrice();

        if (response_price?.data?.msg === "Token invalid") {
          sessionStorage.clear();
        }

        if (response_price.length > 0) {
          const priceURL = response_price;
          sessionStorage.setItem("priceURL", priceURL);
          window.open(priceURL, "_blank");
        }
      }
    } catch (error) {
      console.error("Error al obtener el url price:", error);
    }
  };

  return { handleTokenPrice };
};
