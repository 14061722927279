import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import { NavLink } from "react-router-dom";

const RedeenNights = ({ hotels }) => {
  const seaHorseIndex = hotels?.findIndex(
    (hotel) => hotel.titulo === "Hotel Sea Horse"
  );
  if (seaHorseIndex !== -1 && !!hotels) {
    const seaHorseHotel = hotels[seaHorseIndex];
    hotels.splice(seaHorseIndex, 1);
    hotels.push(seaHorseHotel);
  }
  console.log(hotels);

  return (
    <article className="container-redeen-nights">
      <h1 className="title-redeen-nights">Redime tus noches</h1>
      <Swiper
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        grabCursor={true}
        centeredSlides={true}
        spaceBetween={60}
        loop={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
      >
        {hotels?.map((item) => (
          <SwiperSlide key={item.id}>
            <NavLink to={`/Solicitar/${item.id}/${item.titulo}`} key={item.id}>
              <img
                src={item.portada}
                alt={item.titulo}
                className="image-slider-nights"
              />
              <span className="ciudad-nights">{item.ciudad}</span>
              <span className="name-hotel-nights">{item.titulo}</span>
            </NavLink>
          </SwiperSlide>
        ))}
      </Swiper>
    </article>
  );
};

export default RedeenNights;
