import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Signin from "../components/auth/signin";
import PageNotFound from "../components/err/404";
import Booking from "../pages/booking";
import Alliance from "../pages/alliance";
import Support from "../pages/support";
import Bid from "../pages/bid";
import ReactGA from "react-ga";
import AuthWrapper from "../common/AuthWrapper";
import InactiveUser from "../pages/InactiveUser";
import Success from "../pages/Success";
import PlaceOrder from "../pages/PlaceOrder";
import Password from "../pages/Password";
import RequestPassword from "../pages/RequestPassword";
import Nights from "../pages/Nights";
import { Nightsconsult } from "../pages/Nightsconsult";
import ChangePassword from "../components/user/ChangePassword";
import { Home } from "../pages/Home";

const TRACKING_ID = "G-BJ1KD1C6WZ";

const RouterApp = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, [pathname]);

    return null;
  };

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/respuesta-pago" element={<Success />} />
        <Route path="/accounts/password/reset" element={<RequestPassword />} />
        <Route
          path="/GRUPO-ALIANZA-COLOMBIAf574395e26b38aeb764dbd457ebf2861fd201625b09db770cf09d92d1c97c413$2y$12$D9G9VeAj2nd7r.Jnm1QqTO6nZxSxkqA3upDh51AaA0iheOg7Tw2wq/:user/:id"
          element={<Password />}
        />
        {/* Rutas protegidas */}
        <Route element={<AuthWrapper />}>
          <Route path="/Home" element={<Home />} />
          <Route path="/reserva" element={<Booking />} />
          <Route path="/usuario" element={<InactiveUser />} />
          <Route path="/alianza-plus" element={<Alliance />} />
          <Route path="/NochesyConvenios" element={<Nights />} />
          <Route path="/pagos" element={<PlaceOrder />} />
          <Route path="/soporte" element={<Support />} />
          <Route path="/Solicitar/:id/:hotel" element={<Bid />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route
            path="/cda6e073d0d042bdf7ff288bb23bbf62159c7f6070cf09d92d1c97c4132y12D9G9VeAj2"
            element={<Nightsconsult />}
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default RouterApp;
