import React, { useEffect } from "react";
import ReactGA from "react-ga";
import ChatWood from "../common/bot/ChatWood";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../styles/btn-float.css";
import SessionManager from "../utils/SessionManager";

const TRACKING_ID = "G-BJ1KD1C6WZ";

const Main = ({ children }) => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const sessionStartTime = localStorage.getItem("sessionStartTime");

    if (token && !sessionStartTime) {
      new SessionManager(40 * 60 * 1000);
    } else if (token && sessionStartTime) {
      new SessionManager(40 * 60 * 1000);
    }
  }, []);

  return (
    <>
      <Navbar />
      <main className="main-content">{children}</main>
      <a
        className="text-white"
        href="tel:+576015520100"
        title="Llámanos a nuestra línea"
      >
        <div className="btn-call">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="svg-navbar"
          >
            <path
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M14.05 6A5 5 0 0118 9.95M14.05 2A9 9 0 0122 9.94M18.5 21C9.94 21 3 14.06 3 5.5c0-.386.014-.77.042-1.148.032-.435.048-.653.162-.851a1.06 1.06 0 01.432-.402C3.842 3 4.082 3 4.562 3h2.817c.404 0 .606 0 .779.066a1 1 0 01.396.278c.122.14.191.33.329.71l1.166 3.206c.16.442.24.662.227.872a1 1 0 01-.182.513c-.121.171-.323.292-.725.534L8 10a12.1 12.1 0 006 6l.821-1.369c.242-.402.363-.604.534-.725a1 1 0 01.513-.182c.21-.014.43.066.872.227l3.207 1.166c.38.138.569.207.709.329a.999.999 0 01.277.396c.067.173.067.375.067.779v2.817c0 .48 0 .72-.1.926a1.06 1.06 0 01-.401.432c-.198.114-.416.13-.85.162-.38.028-.763.042-1.149.042z"
            ></path>
          </svg>
        </div>
      </a>
      <a
        className="text-white"
        href="https://api.whatsapp.com/send?phone=5715520100"
        target="_blank"
        rel="noreferrer noopener"
        title="Escríbenos vía WhatsApp"
      >
        <div className="btn-call" style={{ bottom: "20px", right: "160px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="svg-navbar"
          >
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M3.5 12a8.5 8.5 0 118.5 8.5 8.455 8.455 0 01-4.542-1.314 1 1 0 00-.821-.113l-2.88.859 1.085-2.537a1 1 0 00-.07-.92A8.456 8.456 0 013.5 12zM12 1.5C6.201 1.5 1.5 6.201 1.5 12c0 1.838.473 3.568 1.305 5.073L1.08 21.107a1 1 0 001.206 1.351l4.5-1.342A10.456 10.456 0 0012 22.5c5.799 0 10.5-4.701 10.5-10.5S17.799 1.5 12 1.5zm2.293 12.682l-1.315.926A9.338 9.338 0 0111 13.585a10.202 10.202 0 01-1.624-2.114l.835-.709a1 1 0 00.236-1.232l-1.064-2a1 1 0 00-1.54-.283l-.316.275c-.759.662-1.208 1.75-.836 2.852A12.049 12.049 0 009.586 15c1.813 1.813 3.655 2.527 4.733 2.805.868.223 1.689-.076 2.268-.548l.591-.482a1 1 0 00-.12-1.634l-1.678-1a1 1 0 00-1.088.041z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
      </a>
      <ChatWood />
      <Footer />
    </>
  );
};

export default Main;
