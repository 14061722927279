import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Agreements = ({ hotels = [] }) => {
  return (
    <article className="container-redeen-nights agreements">
      <h1 className="title-redeen-nights">Convenios</h1>
      <section className="container-agreements desktop">
        {hotels?.map((item) => (
          <NavLink
            to={`/Solicitar/${item.id}/${item.titulo}`}
            key={item.id}
            className="add-relative"
          >
            <img
              src={item.portada}
              alt={item.titulo}
              className="image-slider-nights"
            />
            <span className="ciudad-nights">{item.ciudad}</span>
            <span className="name-hotel-nights">{item.titulo}</span>
          </NavLink>
        ))}
      </section>

      {/* Versión Móvil con Slider */}
      <section className="container-agreements mobile">
        <Swiper
          modules={[Navigation, Pagination]}
          loop={true}
          navigation={true}
          pagination={{ clickable: true }}
          spaceBetween={20}
          slidesPerView={1}
        >
          {hotels
            ?.reduce((acc, _, index, arr) => {
              if (index % 2 === 0) {
                acc.push(arr.slice(index, index + 2));
              }
              return acc;
            }, [])
            .map((pair, idx) => (
              <SwiperSlide key={idx}>
                <div className="slide-pair">
                  {pair.map((item) => (
                    <NavLink
                      to={`/Solicitar/${item.id}/${item.titulo}`}
                      key={item.id}
                      className="add-relative"
                    >
                      <img
                        src={item.portada}
                        alt={item.titulo}
                        className="image-slider-nights"
                      />
                      <span className="ciudad-nights">{item.ciudad}</span>
                      <span className="name-hotel-nights">{item.titulo}</span>
                    </NavLink>
                  ))}
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </section>
    </article>
  );
};

export default Agreements;
