import React, { useEffect, useCallback } from "react";
import { useSection } from "../context/SectionContext";
import { Suspense, lazy } from "react";
import Load from "../common/Load";

const Welcome = lazy(() => import("../components/scroll/Welcome"));
const Nights = lazy(() => import("./Nights"));
const Alliance = lazy(() => import("./alliance"));
const PlaceOrder = lazy(() => import("./PlaceOrder"));

export const Home = () => {
  const { setActiveSection } = useSection();

  const handleScroll = useCallback(() => {
    const sections = ["welcome", "nights", "alliance", "placeOrder"];
    let currentSection = "";

    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (
        element &&
        element.getBoundingClientRect().top <= window.innerHeight / 2
      ) {
        currentSection = section;
      }
    });

    if (currentSection !== "") {
      setActiveSection(currentSection);
    }
  }, [setActiveSection]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <Suspense fallback={<Load />}>
      <div id="welcome">
        <Welcome />
      </div>
      <div id="nights">
        <Nights />
      </div>
      <div id="alliance">
        <Alliance />
      </div>
      <div id="placeOrder">
        <PlaceOrder />
      </div>
    </Suspense>
  );
};
