import React, { useEffect, useState } from "react";
import { getHotels } from "../service/getHotels";
import { useNavigate } from "react-router-dom";
import RedeenNights from "../components/swiper/RedeenNights";
import Agreements from "../components/agreements/Agreements";
import "../components/swiper/RedeenNights.css";
import "../styles/components/swiper-custom.css";

const Nights = () => {
  const [Hotelsnoches, setHotelsnoches] = useState(null);
  const [Hotels, setHotels] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      localStorage.getItem("state_mora") === "true" ||
      localStorage.getItem("price") === ""
    )
      navigate(-1);
    async function fetchData() {
      try {
        const response = await getHotels();
        if (response.data.msg === "Token invalid") {
          localStorage.clear();
          return navigate("/");
        }
        const hotelsWithConvenios = response.data.filter(
          (hotel) => hotel.convenio === 1
        );
        const hotelsWithoutConvenios = response.data.filter(
          (hotel) => hotel.convenio === 0
        );
        setHotelsnoches(
          hotelsWithoutConvenios.sort((a, b) => new Date(a.id) - new Date(b.id))
        );
        setHotels(
          hotelsWithConvenios.sort((a, b) => new Date(a.id) - new Date(b.id))
        );
      } catch (error) {
        console.error(error);
      }
    }
    var obj = document.getElementById("body");
    obj.style.removeProperty("overflow");
    fetchData();
  }, [navigate]);

  return (
    <section className="bg-azul-degrade">
      <RedeenNights hotels={Hotelsnoches} />
      <Agreements hotels={Hotels} />
    </section>
  );
};

export default Nights;
