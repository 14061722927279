import { NavLink } from "react-router-dom";

const Success = () => {
  return (
    <div className="container-success-pay">
      <div className="card1">
        <h1>
          <i>Pago Exitoso</i>
        </h1>
        <h2>
          Gracias por realizar un abono a tu membresía vacacional, ya quedo
          registrado exitosamente, puedes disfrutar de todos los beneficios que
          tenemos para ti.
        </h2>
        <NavLink to="/">
          <button className="btn-return">Regresar al inicio</button>
        </NavLink>
      </div>
    </div>
  );
};

export default Success;
