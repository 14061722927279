import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getInfo } from "../../service/getInfo";
import baseUrl from "../../utils/baseUrl";
import useTimeOut from "../hook/useTimeOut";
import Load from "../Load";
import { useUserMora } from "../../context/userMoraContext";
import { createAuditory } from "../../service/auditory";
import toast from "react-hot-toast";
import UserMora from "../UserMora";

const DetailsPay = ({ handleChange, form_pay, setform_pay, setGoSteps }) => {
  const [data_pay, setData_pay] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { isStateMora, setIsStateMora } = useUserMora();

  const formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  const getPay = async () => {
    try {
      const url = `${baseUrl}/api/users/payments`;
      const resp = await axios.post(
        url,
        { codigo: user.validaciones.codigo },
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      if (resp.data.msg === "Token invalid") {
        localStorage.clear();
        navigate("/");
      } else {
        setData_pay(resp.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getInfoUser = async () => {
      try {
        const response = await getInfo();
        if (response.data.msg === "Token invalid") {
          localStorage.clear();
          navigate("/");
        } else {
          setUser(response.data.data.info);
          setIsStateMora(response.data.data.info.validaciones.mora);
          localStorage.setItem(
            "state_mora",
            response.data.data.info.validaciones.mora
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    getInfoUser();
  }, [navigate, setIsStateMora]);

  const registerAuditory = async () => {
    try {
      await createAuditory({
        contrato: user.contracto.contrato,
        titular: user.afiliado1.titular,
        email: user.afiliado1.email || user.afiliado2.email,
        pagina: "priority",
      });
    } catch (error) {
      toast(
        <section>
          <header>
            <h2>¡Atención!</h2>
          </header>
          <p>
            Parece que no tenemos un correo electrónico registrado con tu cédula
            de ciudadanía. Para poder continuar, por favor, contáctanos a
            nuestro soporte a través de WhatsApp haciendo clic en el siguiente
            enlace:
          </p>
          <a
            href="https://api.whatsapp.com/send?phone=573156935204&text=Buen%20d%C3%ADa%20%20%0ARequiero%20ayuda"
            target="_blank"
            role="button"
            aria-label="Contactar a soporte por WhatsApp"
            rel="noopener noreferrer"
            className="text-primary"
          >
            Contactar a Soporte
          </a>
          <p>
            {" "}
            Nuestro equipo estará encantado de ayudarte a resolver cualquier
            inconveniente.
          </p>
        </section>,
        {
          duration: 6000,
        }
      );
    }
  };

  useTimeOut(() => getPay(), 1500);
  useTimeOut(() => registerAuditory(), 2000);

  return (
    <>
      {isStateMora === "1" && (
        <UserMora text={user?.validaciones?.mmora?.slice(37)} />
      )}
      {data_pay ? (
        <section>
          <article className="table-payments">
            <table className="">
              <thead>
                <tr>
                  <th>Fecha de pago</th>
                  <th>Pago al día</th>
                  <th>Pago total</th>
                  <th>Pagar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Fecha de pago">
                    {data_pay.fecha_mora || "Se encuentra al día"}
                  </td>
                  <td data-label="Pago al día">
                    <input
                      type="radio"
                      name="monto"
                      id="pago_al_dia"
                      onClick={() =>
                        setform_pay({
                          ...form_pay,
                          nombre: user.afiliado1.titular,
                          correo: user.afiliado1.email,
                          contrato: user.contracto.contrato,
                          monto: data_pay.pago_al_dia,
                        })
                      }
                    />
                    {" " + formatterPeso.format(data_pay.pago_al_dia || 0)}
                  </td>
                  <td data-label="Pago total">
                    <input
                      type="radio"
                      name="monto"
                      id="pago_total"
                      onClick={() =>
                        setform_pay({
                          ...form_pay,
                          nombre: user.afiliado1.titular,
                          correo: user.afiliado1.email,
                          contrato: user.contracto.contrato,
                          monto: data_pay.pago_total,
                        })
                      }
                    />
                    {" " + formatterPeso.format(data_pay.pago_total || 0)}
                  </td>
                  <td>
                    <form onSubmit={() => setGoSteps(1)}>
                      <div className="input-group-pay">
                        <input
                          type="number"
                          className="input-pay"
                          placeholder="Valor a pagar"
                          autoComplete="off"
                          min="1000"
                          required
                          name="monto"
                          value={form_pay.monto}
                          onChange={(e) =>
                            setform_pay({
                              ...form_pay,
                              nombre: user.afiliado1.titular,
                              correo: user.afiliado1.email,
                              contrato: user.contracto.contrato,
                              monto: e.target.value,
                              codigo: user.validaciones.codigo,
                            })
                          }
                        />
                        <button
                          className="button--submit-pay"
                          // onClick={ () => setGoSteps(1)}
                        >
                          Generar pago
                        </button>
                      </div>
                    </form>
                  </td>
                </tr>
              </tbody>
            </table>
          </article>
          <div className="card-active-pay">
            <p className="p-pago-recurrente">¿Deseas inscribir tu tarjeta?</p>
            <a
              className="btn-concurrent"
              href="https://suscripciones.grupoalianzacolombia.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>Continuar</b>
            </a>
          </div>
        </section>
      ) : (
        <Load />
      )}
    </>
  );
};

export default DetailsPay;
