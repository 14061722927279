import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import ReactGA from "react-ga";
import Load from "../common/Load";
import baseUrl from "../utils/baseUrl";
import UserMora from "../common/UserMora";
import { getInfo } from "../service/getInfo";
import convenios from "../assets/convenios.json";
import { redimirnoches } from "../service/redimir";
import { useUserMora } from "../context/userMoraContext";
import SliderComponent from "../components/slide/Slider";
import { useNavigate, useParams } from "react-router-dom";
import { useHandleTokenPrice } from "../utils/priceUtils";
import { ReactComponent as Calendar } from "../assets/icons/calendar.svg";
import "../styles/nights.css";

const Bid = () => {
  const { id, hotel } = useParams();
  const [oferta, setOferta] = useState(null);
  const ConsumeNoches = [
    "Hotel Sea Horse",
    "Hotel Wayira Beach",
    "Hotel Amazon Leticia",
    "Hotel Blue Cove",
    "Hotel Blue Reef",
    "Hotel Blue tone",
    "Hotel Tower",
    "Hotel Acantilado de la Tierra",
  ];
  const Findhotel = convenios.find((h) => h.nombre === hotel);
  const [user, setUser] = useState("");
  const fechaActual = getFechaActual();
  const [fechasalida, setfechasalida] = useState("");
  const [fecharegreso, setfecharegreso] = useState("");
  const [adultos, setAdultos] = useState(1);
  const [ninos, setNinos] = useState(0);
  const [error, seterror] = useState();
  const [redimida, setredimida] = useState(false);
  const [msjresp, setmsjresp] = useState("");
  const [tipo, settipo] = useState("");
  const [priceUrl, setPriceUrl] = useState(null);
  const [edadesNinos, setEdadesNinos] = useState([]);
  const navigate = useNavigate();
  const { isStateMora } = useUserMora();
  const [actionHotel, setActionHotel] = useState(false);
  const { handleTokenPrice } = useHandleTokenPrice();

  function getFechaActual() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function handleFechaSalida(event) {
    const nuevaFechaSalida = event.target.value;
    // Compara si la nueva fecha de salida es mayor que la fecha de llegada
    if (new Date(nuevaFechaSalida) < new Date(fechaActual)) {
      setfechasalida(fechaActual);
    } else {
      setfechasalida(nuevaFechaSalida);
      if (new Date(nuevaFechaSalida) > new Date(fecharegreso)) {
        // Actualiza la fecha de llegada si es menor
        setfecharegreso(nuevaFechaSalida);
      }
    }
  }

  function handleFechaRegreso(event) {
    const nuevaFecharegreso = event.target.value;
    // Compara si la nueva fecha de salida es mayor que la fecha de llegada
    if (new Date(nuevaFecharegreso) < new Date(fechasalida)) {
      setfecharegreso(fechasalida);
    } else {
      setfecharegreso(nuevaFecharegreso);
    }
  }
  function handleAdultosChange(event) {
    setAdultos(event.target.value);
  }

  function handleNinosChange(event) {
    setNinos(parseInt(event.target.value));
    setEdadesNinos([]); // Reinicia las edades cuando cambia la cantidad de niños
  }

  const handleEdadNinoChange = (index, event) => {
    const nuevaEdad = parseInt(event.target.value);
    if (nuevaEdad >= 0 && nuevaEdad <= 18) {
      const nuevasEdades = [...edadesNinos];
      nuevasEdades[index] = {
        edad: nuevaEdad,
        unidad: event.target.nextSibling.value,
      };
      setEdadesNinos(nuevasEdades);
    }
  };

  const handleUnidadEdadChange = (index, event) => {
    const nuevasEdades = [...edadesNinos];
    nuevasEdades[index] = {
      edad: nuevasEdades[index]?.edad || 0,
      unidad: event.target.value,
    };
    setEdadesNinos(nuevasEdades);
  };

  const obtenerTextoEdades = () => {
    return edadesNinos
      .map(({ edad, unidad }) => `${edad} ${unidad}`)
      .join(", ");
  };

  //Services GAC
  const getBidById = useCallback(async () => {
    try {
      const url = `${baseUrl}/api/ofertas/ofertaById/${id}`;
      const response = await axios.get(url, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });

      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        navigate("/");
      } else {
        setOferta(response.data[0]);
        settipo(
          response.data[0].convenio === 1
            ? "Convenio con Descuento"
            : "Redime Noches"
        );
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
    }
  }, [id, navigate]);

  const getInfoUser = useCallback(async () => {
    try {
      const response = await getInfo();
      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        navigate("/");
      } else {
        if (Object.keys(response.data.data.info.afiliado1).length > 0) {
          setUser(response.data.data.info);
        } else {
          navigate("/usuario");
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
      }
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fechasalida && fecharegreso) {
      try {
        const resp = await redimirnoches({
          user,
          adultos,
          ninos,
          fechasalida,
          fecharegreso,
          oferta,
          tipo,
          edad_niños: obtenerTextoEdades(),
        });
        if (resp?.err) {
          seterror("Hubo un error con la solicitud");
          throw new Error(resp.err);
        }
        setmsjresp(resp);
        setredimida(true);
        seterror(null);
      } catch (error) {
        console.log(error);
      }
    } else {
      seterror("Porfavor complete todos los campos");
    }
    ReactGA.event({
      category: "Pagina de oferta",
      action: "Ingreso pagina de oferta",
      label: "Notificación Ingreso pagina de oferta",
      value: `${user.afiliado1.titular} redimio el cupon ${oferta.titulo}`,
    });
  };

  useEffect(() => {
    getInfoUser();
    getBidById();
    ReactGA.pageview(window.location.pathname); //Google Analytics
    setPriceUrl(sessionStorage.getItem("priceURL"));
  }, [getInfoUser, getBidById]);

  const abrirDosPestanas = () => {
    handleTokenPrice(isStateMora);

    const check = localStorage.getItem("priceView");
    if (check !== "true" && priceUrl?.includes("https")) {
      window.open(priceUrl, "_blank");
      localStorage.setItem("priceView", true);
    }
  };

  const createReservationLink = (isStateMora, Findhotel, abrirDosPestanas) => {
    const target = isStateMora !== "1" ? "_blank" : undefined;

    return (
      <a
        href={isStateMora !== "1" && Findhotel.url ? Findhotel.url : "#"}
        target={target}
        rel="noopener noreferrer"
        className="btn-reserva"
        onClick={abrirDosPestanas}
      >
        <Calendar className="svg-navbar" />
        <span className="label">Reservar</span>
      </a>
    );
  };

  return (
    <section className="container-nights">
      {oferta != null ? (
        <div className="container">
          <h1 className="title-sections text-center texto ">
            {oferta?.titulo}
          </h1>
          <SliderComponent oferta={oferta} />
          <h3 className="text-center title-alianza-plus">{oferta.hotel}</h3>
          <div className="centrar">
            {ConsumeNoches.find((h) => h === hotel) && (
              <>
                {isStateMora === "1" ? (
                  <button
                    className="btn-oferta"
                    onClick={() => setActionHotel(true)}
                  >
                    Solicitar
                  </button>
                ) : (
                  <button
                    className="btn-oferta"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Solicitar
                  </button>
                )}
              </>
            )}
            {Findhotel?.url?.length > 0 && (
              // <a
              //   href={
              //     isStateMora !== "1" && Findhotel.url ? Findhotel.url : "#"
              //   }
              //   target="_blank"
              //   rel="noopener noreferrer"
              //   className="btn-reserva"
              //   onClick={() => abrirDosPestanas()}
              // >
              //   <Calendar className="svg-icon" />
              //   <span className="lable">Reservar</span>
              // </a>
              <>
                {createReservationLink(
                  isStateMora,
                  Findhotel,
                  abrirDosPestanas
                )}
              </>
            )}
          </div>
          <article
            className="hotel-info"
            dangerouslySetInnerHTML={{
              __html: oferta?.descripcion,
            }}
          ></article>

          {user &&
            (user?.validaciones.mora === "1" && actionHotel ? (
              <UserMora text={user?.validaciones?.mmora?.slice(37)} />
            ) : (
              <div
                className="modal fade"
                id="exampleModal"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" style={{ borderRadius: "20px" }}>
                  <div
                    className="modal-content"
                    style={{ borderRadius: "20px" }}
                  >
                    <img
                      src={oferta?.portada}
                      alt="miniatura"
                      style={{ borderRadius: "20px" }}
                    />
                    <div>
                      <h5 className="mt-2" style={{ textAlign: "center" }}>
                        {oferta?.titulo}
                      </h5>
                    </div>
                    <div className="modal-body">
                      <h5 style={{ textAlign: "center" }}>Fechas</h5>
                      <div className="row">
                        <div className="col-md-6 modalnochesbox">
                          <label className="modalnochestext">
                            Fecha Salida:
                          </label>
                          <input
                            className="form-date__input"
                            type="date"
                            value={fechasalida}
                            min={fechaActual}
                            onChange={handleFechaSalida}
                          />
                        </div>
                        <div className="col-md-6 modalnochesbox">
                          <label className="modalnochestext">
                            Fecha Regreso:
                          </label>
                          <input
                            className="form-date__input"
                            type="date"
                            value={fecharegreso}
                            min={fechasalida}
                            onChange={handleFechaRegreso}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "20px" }}>
                        <h5 style={{ textAlign: "center" }}>Integrantes</h5>
                        <div className="col-md-6 modalnochesbox">
                          <label className="modalnochestext">Adultos:</label>
                          <select
                            value={adultos}
                            onChange={handleAdultosChange}
                            className="selectpersonas"
                          >
                            {[...Array(10)].map((_, index) => (
                              <option key={index} value={index + 1}>
                                {index + 1}
                              </option>
                            ))}
                            <option value="10+">Más de 10</option>
                          </select>
                        </div>
                        <div className="col-md-6 modalnochesbox">
                          <label className="modalnochestext">Niños:</label>
                          <select
                            value={ninos}
                            onChange={handleNinosChange}
                            className="selectpersonas"
                          >
                            {[...Array(11)].map((_, index) => (
                              <option key={index} value={index}>
                                {index}
                              </option>
                            ))}
                            <option value="10+">Más de 10</option>
                          </select>
                        </div>
                        <section className="row">
                          {ninos > 0 && (
                            <h5 className="centrar mt-2">
                              Edad de los menores
                            </h5>
                          )}
                          <div>
                            {Array.from({ length: ninos }, (_, index) => (
                              <div
                                key={index}
                                className="input-group justify-content-center"
                              >
                                <label className="input-group-text">
                                  Edad del niño {index + 1}:
                                  <input
                                    className="form-control"
                                    type="number"
                                    value={edadesNinos[index]?.edad || ""}
                                    onChange={(event) =>
                                      handleEdadNinoChange(index, event)
                                    }
                                    min={0}
                                    max={17}
                                    required
                                  />
                                  <select
                                    className="selectpersonas"
                                    value={edadesNinos[index]?.unidad}
                                    onChange={(event) =>
                                      handleUnidadEdadChange(index, event)
                                    }
                                  >
                                    <option value="años">Años</option>
                                    <option value="meses">Meses</option>
                                  </select>
                                </label>
                              </div>
                            ))}
                          </div>
                        </section>
                      </div>
                    </div>
                    {error && (
                      <h6
                        style={{
                          color: "red",
                          textAlign: "center",
                        }}
                      >
                        {error}
                      </h6>
                    )}
                    <div className="modal-footer centrar">
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        data-bs-dismiss="modal"
                      >
                        Cancelar
                      </button>
                      <button
                        className="btn-oferta"
                        disabled={redimida}
                        onClick={(e) => handleSubmit(e)}
                      >
                        Solicitar Disponibilidad
                      </button>
                    </div>
                  </div>

                  {redimida ? (
                    <div className="modal-dialog dialog-nochesreservada">
                      <div
                        className="modal-content"
                        style={{
                          borderRadius: "1rem",
                          width: "80%",
                        }}
                      >
                        <div
                          className="card-nochereservada"
                          style={{ width: "100%", padding: "40px" }}
                        >
                          <h6 className="text-center">{msjresp}</h6>

                          <div className="modal-footer centrar">
                            <button
                              type="button"
                              className="btn-oferta"
                              data-bs-dismiss="modal"
                              onClick={() => navigate("/NochesyConvenios")}
                            >
                              Continuar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
        </div>
      ) : (
        <Load />
      )}
    </section>
  );
};

export default Bid;
