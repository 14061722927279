import { useState } from "react";
import { useSection } from "../context/SectionContext";
import { NavLink, useNavigate } from "react-router-dom";
import { useUserMora } from "../context/userMoraContext";
import { ReactComponent as IconPay } from "../assets/icons/pay.svg";
import { ReactComponent as IconOff } from "../assets/icons/off.svg";
import { ReactComponent as IconAccount } from "../assets/icons/account.svg";
import { ReactComponent as Menu } from "../assets/icons/menu-hambuger.svg";
import "../styles/navbar.css";
import SessionManager from "../utils/SessionManager";
import { useHandleTokenPrice } from "../utils/priceUtils";

const Navbar = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const { isStateMora } = useUserMora();
  const { activeSection } = useSection();
  const user = localStorage.getItem("token");
  const navigate = useNavigate();
  const sessionManager = new SessionManager();
  const { handleTokenPrice } = useHandleTokenPrice();

  const handleNavigation = (section) => {
    navigate("/home", { replace: true });
    setTimeout(() => {
      document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
    }, 100);
    setShowOffcanvas(false); // Cerrar el menú después de hacer clic en una opción
  };

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  const handleLogout = () => {
    sessionManager.logout();
  };

  return (
    <header className="container">
      <nav className="navbar navbar-expand-lg fixed-top padding-container">
        <a className="navbar-brand" href="https://grupoalianzacolombia.com">
          <img
            src="/images/logos/logo.png"
            alt="Grupo Alianza Colombia Viajar"
            width={200}
          />
        </a>
        {showOffcanvas && (
          <div
            className="offcanvas-backdrop show"
            onClick={toggleOffcanvas}
          ></div>
        )}
        {user != null && (
          <>
            <section className="menu-mobile-gac" onClick={toggleOffcanvas}>
              <IconAccount className="svg-navbar" />
              <span>Mi cuenta</span>
              <Menu className="menu-hamburguer" onClick={toggleOffcanvas} />
            </section>
            <div
              className={`offcanvas offcanvas-end ${
                showOffcanvas ? "show" : ""
              }`}
              tabIndex="-1"
              style={{ height: "100dvh" }}
            >
              <div className="offcanvas-header">
                <a
                  className="navbar-brand"
                  href="https://grupoalianzacolombia.com"
                >
                  <img
                    src="/images/logos/logo.png"
                    alt="Grupo Alianza Colombia Viajar"
                    width={200}
                  />
                </a>
                <button
                  type="button"
                  className="btn-close"
                  onClick={toggleOffcanvas}
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav">
                  <li
                    className={`option-nav ${
                      activeSection === "welcome" ? "active" : ""
                    }`}
                    onClick={() => handleNavigation("welcome")}
                  >
                    <span>Inicio</span>
                  </li>
                  <li
                    className={`option-nav ${
                      activeSection === "nights" ? "active" : ""
                    }`}
                    onClick={() => handleNavigation("nights")}
                  >
                    <span>REDIME TUS NOCHES</span>
                  </li>

                  <li
                    onClick={() => handleTokenPrice(isStateMora)}
                    className="option-nav"
                  >
                    <span>Alianza Priority</span>
                  </li>
                  <li
                    className={`option-nav ${
                      activeSection === "alliance" ? "active" : ""
                    }`}
                    onClick={() => handleNavigation("alliance")}
                  >
                    <span>Alianza Plus</span>
                  </li>

                  <li
                    className={
                      dropdown
                        ? "nav-item dropdown active-dropdown"
                        : "nav-item dropdown"
                    }
                    onClick={() => setDropdown(!dropdown)}
                  >
                    <div
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="dropdown"
                      className={` option-nav ${
                        activeSection === "placeOrder" ? "active" : ""
                      }`}
                    >
                      <IconAccount className="svg-navbar" />
                      Mi cuenta
                    </div>
                    <div>
                      <ul className="dropdown-menu">
                        <li
                          className="list-group-item-gac"
                          onClick={() => handleNavigation("placeOrder")}
                        >
                          <NavLink className="p-2">
                            <IconPay className="svg-navbar" />
                            Pagos
                          </NavLink>
                        </li>
                        <hr className="divider-gac" />
                        <li
                          className="list-group-item-gac"
                          onClick={handleLogout}
                        >
                          <NavLink className="p-2" to="/">
                            <IconOff className="svg-navbar" />
                            Cerrar Sesión
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </nav>
    </header>
  );
};

export default Navbar;
