import React, { useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import DetailsPay from "../common/pay/DetailsPay";
import MakePayment from "../common/pay/MakePayment";
import SelectPaymentGateway from "../common/pay/SelectPaymentGateway";
import InternationalPayments from "../common/pay/InternationalPayments";
import InfoMembership from "../components/user/InfoMembership";
import "../styles/components/payments.css";

const styleConfig = {
  activeBgColor: "#003893", // Fondo azul para el paso activo
  activeTextColor: "#ffffff", // Texto blanco para el paso activo
  completedBgColor: "#003893", // Fondo verde para el paso completado (puedes ajustarlo a tu preferencia)
  completedTextColor: "#ffffff", // Texto blanco para el paso completado
  inactiveBgColor: "#ffffff", // Fondo blanco para el paso inactivo
  inactiveTextColor: "#003893", // Texto azul para el paso inactivo
  size: "3em", // Tamaño del paso
  circleFontSize: "1rem", // Tamaño de la fuente dentro del círculo
  labelFontSize: "0.875rem", // Tamaño de la fuente del label
  borderRadius: "50%", // Bordes redondeados para los pasos
  fontWeight: 500, // Peso de la fuente del label
};

const connectorStyleConfig = {
  disabledColor: "#003893", // Color gris para los conectores deshabilitados
  activeColor: "#003893", // Color azul para los conectores activos
  completedBgColor: "#003893", // Color verde para los conectores completados
  size: 2, // Grosor de la línea
  style: "dashed", // Estilo de la línea como discontinua (dashed)
};

const INITIAL_STATE = {
  step: 1,
  monto: "",
  nombre: "",
  correo: "",
  contrato: "",
  pasarela: "",
  codigo: "",
  url_payu: null,
};

const PlaceOrder = () => {
  const [goSteps, setGoSteps] = useState(0);
  const [form_pay, setform_pay] = useState(INITIAL_STATE);
  useEffect(() => {
    var obj = document.getElementById("body");
    obj.style.removeProperty("overflow");
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setform_pay((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePreviousStep = () => {
    setform_pay({ ...form_pay, url_payu: null });
    setGoSteps((prevStep) => prevStep - 1);
  };
  const renderStepContent = () => {
    switch (goSteps) {
      case 0:
        return (
          <DetailsPay
            handleChange={handleChange}
            form_pay={form_pay}
            setform_pay={setform_pay}
            setGoSteps={setGoSteps}
          />
        );
      case 1:
        return (
          <InternationalPayments
            handleChange={handleChange}
            form_pay={form_pay}
            setform_pay={setform_pay}
            setGoSteps={setGoSteps}
          />
        );
      case 2:
        return (
          <SelectPaymentGateway
            setform_pay={setform_pay}
            form_pay={form_pay}
            setGoSteps={setGoSteps}
          />
        );
      case 3:
        return (
          <MakePayment
            setform_pay={setform_pay}
            form_pay={form_pay}
            setGoSteps={setGoSteps}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="container-section-payments">
      <InfoMembership />
      <div className="container-table-payments">
        <Stepper
          activeStep={goSteps}
          styleConfig={styleConfig}
          connectorStyleConfig={connectorStyleConfig}
          connectorStateColors={true}
        >
          <Step
            onClick={() => setGoSteps(0)}
            label="Detalles de pago"
            className="Prueba"
          />
          <Step onClick={() => setGoSteps(1)} label="Tipo de pago" />
          <Step onClick={() => setGoSteps(2)} label="Pasarela de pago" />
          <Step onClick={() => setGoSteps(3)} label="Realizar pago" />
        </Stepper>
        {renderStepContent()}

        {goSteps > 0 && (
          <div className="btn-atras">
            <button onClick={handlePreviousStep}>
              <img src="/Button-Atras.svg" alt="Btn-Atras" />
            </button>
            <p>Atrás</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlaceOrder;
