import React, { useEffect } from "react";
import ReactGA from "react-ga";

const Alliance = () => {
  useEffect(() => {
    ReactGA.initialize("G-BJ1KD1C6WZ");

    ReactGA.pageview(window.location.pathname); //Google Analytics
    ReactGA.event({
      category: "Pagina de alianza plus",
      action: "Pagina de alianza plus",
      label: "Notificación Pagina de alianza plus",
    });
  }, []);

  return (
    <a
      href="https://alianzaplus.com/loginafiliado"
      target="_blank"
      rel="noopener noreferrer"
      className="add-relative alianzaplus"
    >
      <picture>
        {/* Imagen para pantallas grandes */}
        <source
          media="(min-width: 1200px)"
          srcSet="https://53fde1b606cff91e7a3b23ff9c242152.grupoalianzacolombia.com/uploads/imagenes_web_priority/img_alianza_plus_banner.webp"
        />
        {/* Imagen para pantallas menores a 1200px */}
        <source
          media="(max-width: 1199px)"
          srcSet="https://53fde1b606cff91e7a3b23ff9c242152.grupoalianzacolombia.com/uploads/imagenes_web_priority/MOBILE__ALIANZA_PLUS.png"
        />
        {/* Imagen por defecto en caso de que los media queries no se apliquen */}
        <img
          src="https://53fde1b606cff91e7a3b23ff9c242152.grupoalianzacolombia.com/uploads/imagenes_web_priority/img_alianza_plus_banner.webp"
          alt="Alianza plus"
          className="img-fluid"
        />
      </picture>

      <button className="btn-alianza-plus">
        <span>Accede</span>
      </button>
    </a>
  );
};

export default Alliance;
